<template>
  <!-- <email-confirmation-component /> -->
  <div class="form form-col-1 mt-2 mb-2">
    <div class="form-content text-center">
      <div class="header-images">
        <div class="logo">
          <img
            src="/images/logos/logo-faber.png"
            alt="Faber Castell"
            title="Faber Castell"
          />
        </div>
        <div class="featured">
          <img
            class="w-auto"
            src="/images/decorations/forgot-password.png"
            alt="Email éxitoso"
            title="Email éxitoso"
          />
        </div>
      </div>
      <div class="body">
        <h1 class="font_helvetica_neue75_bold px-2">¡Correo enviado!</h1>
        <p class="font_helvetica_neue55_roman mt-1 max-w">
          El siguiente paso es revisar el email que te hemos enviado y confirmar
          la recuperación de tu contraseña
        </p>
      </div>
      <div class="buttons">
        <span class="btn-primary-outline-static pl-1 pr-1"
          >Si ya confirmaste tu correo electrónico, ya puedes cerrar esta
          ventana</span
        >
      </div>
      <div class="footer max-w mt-2">
        <p class="font_helvetica_neue55_roman">
          ¿Aún no recibiste nuestro email? Revista tu carpeta de spam o
          <router-link class="link pointer" :to="{ name: 'forgot-password' }">
            intenta usando otro correo eléctronico.
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import { onBeforeMount } from 'vue';
import useAuthentication from '@/modules/auth/composables/authentication/useAuthentication';

export default {
  setup() {
    onBeforeMount(() => {
      const { validateAuthentication } = useAuthentication();
      validateAuthentication('email-confirmation-success');
    });
  },
};
</script>
